.sign-as-login-section span i {
    color: var(--grey);
}

.divider-line {
    width: 100%;
    height: 1px;
    background: #b3b3b3;
    margin: auto;
}

.login-input-section input {
    border-radius: 50px !important;
    padding: 10px 20px;
    background: #296df51a;
    border: 1px solid var(--primary-color) !important;
    font-weight: 500;
    color: #636363;
}

.login-input-section input::placeholder {
    font-style: italic;
    font-weight: 500;
    color: #636363;
}

.login-input-section input:focus {
    box-shadow: none !important;
    outline: none !important;
}

.signup-section .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 25px 0;
    padding: 0;
    color: var(--grey);
    font-weight: 600;
    font-size: 14px;
}

.signup-section .form-check a {
    font-style: italic;
}

.signup-section .form-check input.form-check-input {
    width: 22px;
    height: 22px;
    margin: 0;
    border: 1px solid var(--primary-color);
}

.signup-section .form-check input.form-check-input:focus {
    box-shadow: none !important;
}

.signup-section .form-check input.form-check-input:checked {
    background-color: var(--primary-color);
}

.or-text-line {
    color: #a3a3a3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    position: relative;
}

.or-text-line:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #a3a3a3;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
}

.or-text-line span {
    background: #f3f8ff;
    padding: 0 10px;
    display: block;
}

.social-login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.social-login span {
    background: var(--white);
    border-radius: 50px;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-shadow: 0 4px 10px 0 #00000026;
    cursor: pointer;
    color: #636363;
    font-weight: 600;
}

.form-footer-text {
    font-weight: 500;
    color: #a3a3a3;
    font-size: 14px;
}

.form-footer-text a {
    font-style: italic;
}

.signup-section .react-tel-input .form-control {
    width: 100% !important;
}

.signup-section .country-flag {
    height: 58px;
    margin-bottom: 15px;
}
.signup-section .padding-end {
    padding-right: 11px;
}
.signup-section .form-text {
    display: block;

    padding-left: 20px;
}
.signup-section .form-text:not(:last-child) {
    margin-top: -13px;
}
