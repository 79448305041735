.wizard-form .form-control,
.wizard-form .form-select {
  border-radius: 15px !important;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid var(--primary-color) !important;
  font-weight: 500;
  color: #636363;
  height: 50px;
}

.wizard-form .form-control::placeholder,
.wizard-form .form-select {
  font-style: italic;
  font-weight: 500;
  color: #636363;
}

.wizard-form .form-control:focus,
.wizard-form .form-select:focus {
  box-shadow: none !important;
  outline: none !important;
}

.wizard-form .form-check.form-switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0;
  align-items: center;
  gap: 10px;
}

.wizard-form .form-check.form-switch > input {
  float: none;
  margin: 0;
  height: 22px;
  width: 40px;
  border: 1px solid #bfbfbf;
}

.wizard-form .form-check.form-switch > input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

button.add-icon-btn {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  height: 35px;
}

button.add-icon-btn i {
  font-size: 20px;
}

.steps-count {
  padding: 20px 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.steps-count li {
  width: 15px;
  height: 7px;
  background: #ddd;
  border-radius: 50px;
}

.steps-count li.step-active {
  height: 15px !important;
  background: var(--primary-color);
}

.increase-decrease-btn {
  width: 25px;
  height: 25px;
  background: #296cf524;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 10px;
  color: var(--primary-color);
  font-size: 20px;
  cursor: pointer;
}

.country-flag .flag-div {
  border-radius: 15px !important;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid var(--primary-color) !important;
  font-weight: 500;
  color: #636363;
  height: 50px;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 140px;
  position: relative;
}

.country-flag .flag-div .text-danger {
  position: absolute;
  top: 100%;
  left: 0;
}

.country-flag .mobile-number-section {
  flex: 1;
}

.country-flag .flag-div .form-control {
  padding: 0;
  height: auto;
  border: none !important;
  border-radius: 0 !important;
}

.message-icon-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 2px 0;
}

.message-icon-box img {
  width: 30px;
}

.folder-list-item {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #296cf51a;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 #00000041;
  position: relative;
  margin: 0 0 20px;
}

.folder-list-item .folder-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
}

.folder-list-item .folder-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.folder-list-item .folder-content {
  flex: 1;
}

.folder-list-item .folder-content .button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
}

.folder-list-item .edit-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
}

.add-folder-modal .modal-content {
  background-color: #f3f8ff;
}

.video-settings-upload {
  position: relative;
  padding: 10px 0;
  margin: 0 0 10px;
}

.video-settings-upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  cursor: pointer;
}

.video-settings-upload label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #888;
  font-weight: 600;
}

.video-settings-upload label {
  border: 1px solid #888;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  background: #fff;
}

.video-settings-upload label i {
  font-size: 30px;
}

.invite-people-btn {
  padding: 2px 10px;
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
}
