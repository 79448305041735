@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("before-login.css");
@import url("responsive.css");
@import url("step-wizard.css");
@import url("dashboard.css");

:root {
  --primary-color: #296cf5;
  --white: #fff;
  --black: #000;
  --grey: #9a9a9a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  margin: 0;
}

p {
  margin: 0;
  line-height: 1.7;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

.min-height-60 {
  min-height: 60px;
}

body {
  background: #f3f8ff;
  font-family: "Open Sans", sans-serif !important;
}

.text-danger {
  font-size: 14px;
  padding: 5px;
  font-weight: 600;
}

/* button-css-start */

.btn {
  border-radius: 50px !important;
  padding: 0.6rem 1.5rem !important;
  font-weight: 600 !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-width: 1px !important;
}

.btn-primary:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary-outline {
  border-width: 1px;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary-outline:hover {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  border-color: var(--primary-color) !important;
}

/* button-css-end */

/* navbar-css-start */

header {
  background: #fff;
}

header>nav {
  box-shadow: 0 4px 20px 0 #00000026;
}

header>nav .navbar-nav a {
  padding: 0 15px !important;
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
}

header>nav .navbar-nav a:hover {
  color: var(--primary-color) !important;
}

header>nav .navbar-toggler {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

header>nav .navbar-toggler:focus {
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .navbar-nav {
    padding: 0 !important;
    gap: 20px;
  }

  .navbar-nav a {
    padding: 0 15px !important;
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
  }
}

/* navbar-css-end */

/* back-button-css-start */

.back-button {
  width: fit-content;
  margin: 0 0 -30px;
  cursor: pointer;
}

/* back-button-css-end */

/* loader-css-start */

.loading-loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* loader-css-end */

/* success-message-css-start */

.success-message-section {
  background: #12d1811a;
  display: flex;
  gap: 5px;
  padding: 20px;
  font-size: 13px;
  border-radius: 10px;
  color: #7d7d7d;
  font-weight: 500;
}

/* success-message-css-end */

/* grid-item-css-start */

.grid-card {
  width: 100%;
  padding: 20px;
  background: #296df51a;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 #00000041;
  margin: 0 0 15px;
}

.grid-card .grid-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-card .grid-card-header h6 {
  font-weight: 700;
}

.grid-card .grid-card-body {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  margin: 15px 0;
}

.grid-card .grid-card-body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-card .grid-card-footer {
  display: flex;
  justify-content: flex-end;
}

.grid-card .grid-card-footer .avatar img {
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.grid-card .grid-card-footer .avatars {
  padding-left: 25px;
}

/* grid-item-css-end */

/* list-item-css-start */

.list-item {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #296cf51a;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 #00000041;
  position: relative;
  margin: 0 0 15px;
}

.list-item .list-item-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
}

.list-item .list-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-item .list-item-content {
  flex: 1;
}

.list-item .list-item-content .button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
}

.list-item .edit-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
}

.list-item .avatar img {
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.list-item .avatars {
  padding: 0 !important;
  background: transparent !important;
}

/* list-item-css-end */

/* view-option-dropdown-css-start */

.view_option-dropdown {
  width: fit-content;
  font-weight: 600;
}

.view_option-dropdown .dropdown-toggle {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

.view_option-dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border: 2px solid var(--primary-color);
  padding: 0 !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 0 #00000041;
}

.view_option-dropdown .dropdown-toggle::after {
  content: "\ea4e";
  border: none;
  font-family: "remixicon";
  font-size: 18px;
  line-height: 1;
  margin: 0;
}

.view_option-dropdown .dropdown-menu .form-check {
  padding: 8px 0 8px 35px;
  margin: 0;
  border-bottom: 1px solid var(--primary-color);
  font-size: 14px;
}

.view_option-dropdown .dropdown-menu .form-check:last-child {
  border: none !important;
}

/* view-option-dropdown-css-end */

/* invite-access-dropdown-css-start */

.invite_access-dropdown {
  width: fit-content;
  font-weight: 600;
}

.invite_access-dropdown .dropdown-toggle {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

.invite_access-dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border: 2px solid var(--primary-color);
  padding: 0 !important;
  overflow: hidden !important;
  min-width: 250px;
  box-shadow: 0 4px 4px 0 #00000041;
}

.invite_access-dropdown .dropdown-toggle::after {
  content: "\ea4e";
  border: none;
  font-family: "remixicon";
  font-size: 18px;
  line-height: 1;
  margin: 0;
}

.invite_access-dropdown .search-bar {
  position: relative;
}

.invite_access-dropdown .search-bar i {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.invite_access-dropdown .search-bar input {
  border: none !important;
  border-radius: 0 !important;
  padding: 5px 0 5px 30px !important;
  height: auto;
}

.invite_access-dropdown .user_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.invite_access-dropdown .user_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-top: 1px solid #ddd;
}

.invite_access-dropdown .user_list li .form-check {
  padding: 0 !important;
  margin: 0 !important;
}

.invite_access-dropdown .user_list li .user-data {
  display: flex;
  align-items: center;
  gap: 5px;
}

.invite_access-dropdown .user_list li .user-data img {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.invite_access-dropdown .user_list li .user-data p {
  font-size: 12px;
}

.invite_access-dropdown .user_list li .user-data p small {
  font-style: italic;
  color: #888;
}

/* invite-access-dropdown-css-end */

/* sort-by-dropdown-css-start */

.sort_by-dropdown {
  width: fit-content;
  font-weight: 600;
}

.sort_by-dropdown .dropdown-toggle {
  background: #fff !important;
  padding: 10px !important;
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  box-shadow: 0 4px 4px 0 #00000041;
  border-radius: 10px !important;
}

.sort_by-dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border: 2px solid var(--primary-color);
  padding: 0 !important;
  overflow: hidden !important;
  min-width: 200px;
  box-shadow: 0 4px 4px 0 #00000041;
}

.sort_by-dropdown .dropdown-toggle::after {
  content: "\ea4d";
  border: none;
  font-family: "remixicon";
  font-size: 18px;
  line-height: 1;
  margin: 0;
}

.sort_by-dropdown .dropdown-menu .form-check {
  padding: 8px 0 8px 35px;
  margin: 0;
  border-bottom: 1px solid var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}

.sort_by-dropdown .dropdown-menu .form-check:last-child {
  border: none !important;
}

/* sort-by-dropdown-css-end */

/* edit-option-dropdown-css-start */

.edit_option-dropdown {
  width: fit-content;
  font-weight: 600;
}

.edit_option-dropdown .dropdown-toggle {
  background: transparent !important;
  padding: 0px !important;
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit_option-dropdown .dropdown-toggle::after {
  display: none;
}

.edit_option-dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border: 2px solid var(--primary-color);
  padding: 0 !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 0 #00000041;
}

.edit_option-dropdown .edit_option_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.edit_option-dropdown .edit_option_list li {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

/* edit-option-dropdown-css-end */

/* upload-dropdown-css-start */

.upload_dropdown {
  width: fit-content;
  font-weight: 600;
}

.upload_dropdown .dropdown-toggle {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

.upload_dropdown .dropdown-toggle::after {
  /* content: "\ea4e"; */
  border: none;
  font-family: "remixicon";
  font-size: 18px;
  line-height: 1;
  margin: 0;
}

.upload_dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border: 2px solid var(--primary-color);
  padding: 0 !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 0 #00000041;
}

.upload_dropdown .edit_option_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.upload_dropdown .edit_option_list li {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

/* upload-dropdown-css-end */

.create-folder-modal .modal-dialog {
  max-width: 1024px;
}
.create-folder-modal .modal-content{
  background-color: #f3f8ff;
}
.create-folder-modal .modal-dialog .modal-content .modal-body .form-container {
  max-width: 800px;
  margin: auto;
}

.create-folder-modal .modal-dialog .modal-content .modal-body .form-container .login-input-section input,
.create-folder-modal .modal-dialog .modal-content .modal-body .form-container .login-input-section textarea {
  border-radius: 15px !important;
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid var(--primary-color) !important;
  font-weight: 500;
  color: #636363;
}

.create-folder-modal .modal-dialog .modal-content .modal-body .video-settings-upload label {
  background: #ffffff;
  border: 1px solid var(--primary-color) !important;
  font-size: 16px;
}

.create-folder-modal .modal-dialog .modal-content .modal-body .video-settings-upload label .browse-file {
  color: var(--primary-color);
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--primary-color) !important;
}

.create-folder-modal .modal-dialog .modal-content .modal-body .record-file {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--primary-color) !important;
}

.create-folder-modal .modal-dialog .modal-content .modal-footer .create-btn {
  width: auto;
  font-size: 20px;
  margin-bottom: 15px;
}

.grey-box {
  background: #ededed;
  border-radius: 5px;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.executer-table {
  /* border: none */
}

.executer-table,
td {
  border-spacing: 0px 15px !important;
  border-collapse: separate !important;
}

table.executer-table thead th {
  padding: 8px;
}

table.executer-table tbody tr td div.avatars {
  padding-left: 38px;
}

table.executer-table tbody tr td div .notify {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
}

table.executer-table tbody tr td .invite-user-icon .username-icon {
  width: 70px;
  height: 70px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

table.executer-table tbody tr {
  box-shadow: 0 4px 4px 0 #00000041;
  border-radius: 20px;
}

table.executer-table tbody tr td {
  position: relative;
  border-bottom: 0px;
  background: #296cf51a;
  vertical-align: middle;
  padding: 20px 8px;
  /* border-radius: 20px; */
}

table.executer-table tbody tr td:nth-child(1) {
  border-radius: 20px 0px 0px 20px;
}

table.executer-table tbody tr td:last-child {
  border-radius: 0px 20px 20px 0px;
}

table.executer-table tbody tr td .form-switch .form-check-input {
  width: 50px;
  height: 30px;
}

table.executer-table tbody tr td .form-switch .form-check-input:focus,
table.executer-table tbody tr td .form-switch .form-check-input:focus-visible {
  border-color: #296cf5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

table.executer-table tbody tr td .form-switch .form-check-input:checked {
  background-color: #296cf5;
  border-color: #296cf5;
}

table.executer-table tbody tr td .invite-you {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  font-weight: 600;
  width: auto;
}

table.executer-table tbody tr td .edit-icon {
  position: absolute;
  top: 5px;
  right: 20px;
}

.table-head-color {
  color: #535353 !important;
}

/* table.executer-table tbody tr td */
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 0px !important;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 105px !important;
  outline: none;
}

.wizard-form .form-control {
  padding: 7px 20px;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  /* background: #FFFFFF; */
}

.nothings-file {
  height: calc(100vh - 500px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.add-folder-modal div[name="mobileNumber"] {
  max-width: 282px;
}

.sign-in-tabs {
  /* background-color: #fff; */
  width: 600px;
  margin: 30px auto 50px;
  justify-content: space-between;
  padding: 5px;
  border-radius: 100px;
}

.sign-in-tabs li {
  width: 48%;
  border-radius: 100px !important;
}

.sign-in-tabs li button.nav-link {
  width: 100%;
  border-radius: 100px !important;
  font-size: 22px;
}

.nothings-file .nothings-content {
  text-align: center;
}

.nothings-file .nothings-content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.nothings-file .nothings-content p {
  font-size: 18px;
  font-weight: 400;
}

.trash-tabs li button.nav-link {
  background: #fff;
  color: var(--primary-color);
  border: 1px solid #efefef !important;
}

.trash-tabs li button.nav-link.active {
  background: #fff !important;
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
}

h3.modal-heading {
  font-size: 22px;
  margin-bottom: 30px;
  color: #000;
  font-weight: bold;
}

.form-container .create-new {
  font-size: 14px;
  margin: 0px 0px 10px;
}

.video-container {
  height: 430px;
  position: relative;
  padding-top: 58%;
  border-radius: 20px;
}

.video-container video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.model-header {
  font-size: 18px;
  font-weight: 600;
}

.selected-album {
  background-color: #eff4fb;
  position: relative;
  border-radius: 10px;
}

.selected-album .edit-icon {
  position: absolute;
  top: -10px;
  right: 5px;
}

.more {
  font-size: 26px;
  color: var(--primary-color);
}

.dashboard-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-heading span {
  font-size: 18px;
}

.upload_dropdown .dropdown-toggle.selected {
  background: var(--primary-color) !important;
  color: #fff !important;
}

.schedule-btn {
  background: #fff !important;
  color: var(--primary-color) !important;
  border-radius: 10px !important;
  border: none !important;
}

.schedule-btn:hover {
  background: #fff !important;
  color: var(--primary-color) !important;
  outline: 1px solid var(--primary-color) !important;
}

.more-details {
  font-size: 22px;
  color: #5c5c5c;
}

.album-title-small {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

h4.album-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.box-grey {
  background-color: #f6f6f6;
  border-radius: 15px;
}

.box-grey p {
  color: #545454;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
}

.music-card {
  background: #fff;
  border: none;
}

.music-card .card-header {
  height: 298.61px;
  /* width: 374.17px; */
  padding: 18px 10px;
  border-bottom: 0px;
  border-radius: 10px;
  position: relative;
  background: rgba(41, 108, 245, 10%);
}

.music-card .card-header img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.music-card .card-header .edit-icon {
  position: absolute;
  font-size: 24px;
  color: #545454;
  right: 10px;
  top: -3px;
}

.music-card .card-header svg {
  position: absolute;
  top: 25px;
  left: 20px;
}

.music-card .card-title {
  font-size: 18px;
  font-weight: 800;
  color: #000;
}

.music-card .card-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: 707070;
}

.music-card .card-text {
  font-size: 14px;
  color: #707070;
}

.schedule-album-modal .modal-header {
  padding-bottom: 5px;
  border-bottom: 0px;
}
.schedule-album-modal .modal-dialog,
.preview-media .modal-dialog{
  max-width: 679px;
}
.schedule-album-modal .modal-content {
  /* min-width: 679px !important; */
  height: 534px;
}


.schedule-album-modal .modal-content .modal-body .dashboard-heading {
  justify-content: center !important;
  font-size: 24px !important;
  margin-bottom: 15px;
}

.schedule-album-modal .modal-content .modal-body .text-content {
  font-size: 18px !important;
  color: #000;
}

.schedule-album-modal .modal-content .modal-body form .form-select {
  padding: 15px;
  text-align: center;
  border: 1px solid var(--primary-color);
  background-color: #fff;
  border-radius: 15px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.schedule-album-modal .modal-content .modal-body form .form-select::placeholder {
  color: #707070;
  font-weight: 600;
}

.schedule-album-modal .modal-content .modal-body form .btn-group {
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid var(--primary-color);
}

.schedule-album-modal .modal-content .modal-body form .btn-group .btn-times {
  background-color: #fff !important;
  border: none !important;
  font-size: 16px;
  color: #7e7e7e;
  font-weight: 400 !important;
  border-radius: 14px !important;
}

.schedule-album-modal .modal-content .modal-body form .btn-group .btn-times:hover,
.schedule-album-modal .modal-content .modal-body form .btn-group .selected {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.schedule-album-modal .modal-content .modal-body form .set-time {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 600 !important;
  width: 260px;
}

.schedule-album-modal .modal-content .modal-body form .cancel-modal,
.schedule-album-modal .modal-content .modal-body form .cancel-modal:hover {
  background-color: #ffffff00 !important;
  border: none !important;
  font-size: 20px;
  color: #7e7e7e !important;
  font-weight: 600 !important;
  border-radius: 14px !important;
  width: 260px;
}

.country-num .react-tel-input .form-control {
  width: 100% !important;
  padding-left: 33px;
  padding-right: 0px !important;
}

.country-num .react-tel-input .selected-flag {
  padding-left: 0px;
  width: 30px;
}

.country-num .react-tel-input .flag-dropdown {
  width: 30px;
}

.login-forevermems {
  text-align: center;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 20px;
}

.create-forevermems {
  text-align: center;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.memories-heading {
  font-size: 40px;
  font-weight: 800;
  color: #000;
}

.card-box {
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  filter: drop-shadow(0px, 4px, 6px, rgba(255, 255, 255, 25%));
}

.account-holder-card {
  min-height: 475px;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid #c7c7c7;
}

.account-holder-card .card-header {
  background-color: #fff;
  border: none;
  border-radius: 20px 20px 0px 0px;
}

.account-holder-card .card-header .form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding-left: 0px; */
}

.account-holder-card .card-header .form-check .form-check-input {
  width: 35px;
  height: 35px;
  border: 1px solid var(--primary-color);
}

.account-holder-card .card-header .form-check .form-check-label {
  font-size: 28px;
  font-weight: 800;
  color: #000;
}

.fst-normal {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.account-holder-card .card-header .img-container {
  height: 184px;
  width: 100%;
  border-radius: 20px;
  padding: 10px 0px 0px;
}

.account-holder-card .card-header .img-container img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.list div {
  gap: 10px;
}

.list div .bullets {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background: var(--primary-color);
}

.list div .txt {
  color: #000;
  font-weight: 400;
  font-size: 15px;
  width: 260px;
}

.account-holds {
  border: 1px solid var(--primary-color);
}

.login-input-section .country-flag div.react-tel-input {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 15px !important;
}

.login-input-section .country-flag div.react-tel-input .form-control {
  background: #ffffff;
}

.login-input-section .country-flag div.react-tel-input .flag-dropdown {
  position: absolute;
  top: 4px;
  bottom: 0;
  padding: 0;
  background: #ffffff;
  border: 1px solid #cacaca00;
  border-radius: 3px 0 0 3px;
  height: 40px;
}

.login-input-section .country-flag .telNum {
  border-radius: 15px !important;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid var(--primary-color) !important;
  font-weight: 500;
  color: #636363;
  height: 56px;
}

input.form-control {
  background: #fff;
  border-radius: 15px !important;
}

.login-input-section .pass {
  background: #fff !important;
}

.change-phone div.react-tel-input {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 15px !important;
}

.change-phone .telNum {
  border-radius: 15px !important;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid var(--primary-color) !important;
  font-weight: 500;
  color: #636363;
  height: 56px;
}

.memory-view-container {
  background: #f6f6f6;

  border-radius: 15px;
}

.memory-view-container p {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  color: #545454;
}

table tbody tr td .avatars .avatar img {
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.seach-bar {
  background: rgb(129, 170, 255, 20%);
  margin-top: 10px;
  border-radius: 100px;
  color: var(--primary-color);
  padding: 3px 5px;
}

.seach-bar .form-control {
  max-width: 335px;
  border: none;
  background: transparent;
  text-align: center;
  color: var(--primary-color);
  font-weight: 600;
}

.seach-bar .form-control:focus,
.seach-bar .form-control:focus-visible,
.seach-bar .form-control:focus-within {
  border: none;
  outline: none;
  box-shadow: none;
}

.seach-bar .form-control::placeholder {
  color: var(--primary-color);
  font-weight: 400;
}

.seach-bar i {
  font-size: 22px;
}

.para {
  text-align: center;
  font-style: italic;
  color: #000;
  font-size: 18px;
  margin: 60px 0px 50px;
}

.singin-heading {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.singin-heading span {
  color: var(--primary-color);
  margin-bottom: 40px;
}

/* */

.select-folder-test .invite_access-dropdown .css-13cymwt-control {
  height: 60px;
  border-radius: 15px;
  border: 1px solid var(--primary-color);
}

.select-folder-test2 {
  height: 60px;
  border-radius: 15px !important;
  border: 1px solid var(--primary-color) !important;
}

/* loader-css */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: relative;
  z-index: 3;
}

.loader-container {
  /* content: ""; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000077;
  z-index: 2;
  position: absolute;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fm-not-found-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: rgb(129, 170, 255, 20%);
  padding: 30px;
  border-radius: 15px;
  max-width: 350px;
  margin: auto;
  box-shadow: 2px 4px 4px 0px rgb(3 3 3 / 17%);
}

.fm-not-found-box i {
  color: var(--primary-color);
  font-size: 72px;
}

.fm-not-found-box span {
  font-weight: 600;
  font-size: 18px;
}

.album-not-found {
  max-width: 550px;
  text-align: center;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.album-not-found h5 {
  font-weight: 800;
}

.list-item-content .button-group .avatars .list-item-content h5 {
  line-height: 1.5;
}

.list-item-content .button-group .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
}

.memory-card-footer .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
}

#birthdaytime {
  padding: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  margin-right: 10px;
}

.btn-transparent {
  color: var(--primary-color);
  background: #fff !important;
  border: none !important;
  padding-top: 0px !important;
}

.btn-transparent:hover {
  border: none !important;
}

.preview-media .modal-body .img-container {
  position: relative;
  padding-top: 100%;
}

.preview-media .modal-body .img-container img,
.preview-media .modal-body .img-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preview-media .modal-body .img-container video {
  background-color: #000;
}

.preview-media .modal-body .img-container .spinner-border {
  position: absolute;
  top: 45%;
  left: 45%;
}

.preview-media .modal-header {
  border-bottom: none;
  position: relative;
}

.preview-media .modal-title {
  color: #000;
  margin: auto;
}

.preview-media .modal-header .btn-close {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 2;
}

.preview-media .modal-footer {
  justify-content: center !important;
}

.preview-media .modal-footer button {
  background-color: var(--primary-color);
}

.preview-media .modal-header,
.preview-media .modal-body,
.preview-media .modal-footer {
  background-color: #f3f8ff;
}

.dogller-container {
  position: relative;
}

.dogller-container .edit-icon {
  position: absolute;
  z-index: 9;
  top: 2px;
  right: 10px;
}

.grid-toggler {
  position: relative;
  transition: all 0.2s ease-in;
}

.grid-toggler .edit-icon {
  position: absolute;
  z-index: 9;
  top: 2px;
  right: 10px;
  display: none;
}

.grid-toggler:hover {
  background: #eff4fb;
  border-radius: 10px;
}

.grid-toggler:hover .edit-icon {
  display: block;
}

.grid-toggler:hover .edit-icon i.text-muted {
  color: var(--primary-color) !important;
}

.trash-toggler .edit-icon {
  position: absolute;
  z-index: 9;
  top: 2px;
  right: 10px;
  display: block;
}

.trash-toggler:hover .edit-icon i.text-muted {
  color: #212529bf !important;
}

.grid-toggler:hover .edit-icon {
  display: block;
}

.grid-toggler:hover .edit-icon i.text-muted {
  color: var(--primary-color) !important;
}

.enabledisableInvite {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #e3dede9c !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  font-weight: 600;
  width: auto;
}

.enabledisableInvite .disableButton {
  background: #ccc !important;
}

.viewer-memories-modal .modal-content {
  max-width: 500px;
  margin: auto;
}

.add-executor .modal-body form .form-check-label {
  font-weight: 600;
  /*  */
  color: #000;
  font-size: 18px;
}

.add-executor .modal-body form .form-check {
  margin-bottom: 15px;
}

.add-executor .modal-body form .form-check+div {
  color: var(--primary-color);
  font-style: italic;
  text-align: center;
}

.add-executor .modal-body form .react-tel-input {
  border: 1px solid var(--primary-color);
  padding: 7px;
  border-radius: 15px;
  margin-bottom: 5px;
  height: 50px;
}

.add-executor .modal-body form .react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
}

.invite_access-dropdown .user_list .user-data .username-icon {
  font-size: 12px;
  background-color: var(--primary-color);
  border-radius: 100px;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.account-container .user-icon,
.billing-container .user-icon {
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 22px;
  font-weight: 600;
  border-radius: 100px;
}

.account-container .userPicture img {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.account-container .userPicture {
  width: 70px;
  border-radius: 100px;
  height: 70px;
}

.account-container .user-label,
.billing-container .user-label {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  line-height: 1.5;
}

.account-container .user-txt,
.billing-container .user-txt {
  font-size: 18px;
  font-weight: 400;
  color: #656565;
}

.outline-btn {
  background: var(--white) !important;
  color: var(--primary-color) !important;
  border-radius: 5px !important;
}

/* .grid-info-executor .card .invite-user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: #fff;
} */

.grid-info-executor .card .invite-user-info .invite-user-detail p {
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  font-style: italic;
}

.grid-info-executor .card .card-body .label {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.grid-info-executor .card .card-body .relation-name {
  font-size: 14px;
  color: #666666;
}

.grid-info-executor .card {
  background: #296cf51a;
  box-shadow: 0 4px 4px 0 #00000041;
  border-radius: 20px;
}

.username-icon img,
.invite-user-icon img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.grid-info-executor .card .invite-user-icon {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: #fff;
}

.settings-heading {
  padding-left: 26px;
  color: #5c5c5c;
}

.navigate-popover-pages {
  color: #000000;
}

.privacy-heading {
  font-size: 36px;
  color: #000000;
  font-weight: 600;
}

.privacy-policy .privacy-policy-para {
  font-size: 18px;
  color: #000000;
}

.privacy-policy .privacy-policy-para-label {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}

.circle .avatars .avatar img {
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.modal-body .img-container-profile {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding-top: 70%;
  position: relative;
  background-color: #000;
}

.modal-body .img-container-profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* object-fit: cover; */
}

.modal-body .img-container-profile input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.plan-table .card.p-2 {
  background: #f4f4f4;
  border-radius: 8px;
  border: none !important;
  border-top: 5px solid #acf !important;
}

.plan-table .card p {
  margin: 10px 0 !important;
}

.plan-table .card span {
  color: red;
  font-weight: 600;
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: rotate(13deg);
}

.plan-table .card ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.plan-table .card ul li {
  padding: 8px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}

.plan-table .card ul li:before {
  content: "\eb80";
  font-family: "remixicon" !important;
  font-size: 25px;
  line-height: 1;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 20px;
  gap: 10px;
}

.switch-wrapper .form-switch {
  padding: 0;
  display: flex;
}

.switch-wrapper .form-switch .form-check-input {
  margin: 0 !important;
  width: 3em;
  height: 1.5em;
}

.switch-wrapper .form-check-input:focus {
  box-shadow: none;
  border: none !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.pass-name {
  font-size: 24px;
  color: #000;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 1.5;
}

.close-pass-date-modal {
  border: none !important;
}

.pass-date-modal .modal-dialog {
  width: 679px;
  margin: auto;
}

.pass-date-modal .pass-modal-para {
  font-size: 21px;
  font-weight: 600;
  color: #000000;
}

.result__card {
  border: 2px solid var(--primary-color);
  border-radius: 15px;
}

.estimator__form__input label {
  text-align: start;
  display: block;
  padding: 0 20px;
  font-weight: 700;
}

.estimator__form__input input {
  min-height: 50px;
  border: 2px solid #91b2ff;
  border-radius: 15px !important;
  padding: 0 20px;
}

.estimator__form__input input::placeholder {
  font-weight: 500;
  font-style: italic;
  color: #636363;
}

.personalize_your_plan {
  background: #fff !important;
  padding: 5px 10px !important;
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  box-shadow: 0 4px 4px 0 #00000041;
  border-radius: 8px !important;
  width: fit-content;
  margin: 0 20px 20px;
  font-weight: 500;
  cursor: pointer;
}

.personalize_your_plan i.ri-equalizer-2-fill {
  font-size: 20px;
  color: #5c5c5c;
  line-height: 1;
}

.personalize_your_plan i.ri-arrow-down-s-fill,
.personalize_your_plan i.ri-arrow-up-s-fill {
  font-size: 25px;
  line-height: 1;
}

.gift__checkbox {
  width: fit-content;
  margin: 20px auto 10px;
  color: var(--primary-color);
  font-weight: 600;
}

.gift__checkbox .form-check-input[type="checkbox"] {
  border-radius: 2px;
  border: 1px solid var(--primary-color);
}

.already-login {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.already-para {
  font-size: 18px;
  color: #000;
}

.album_folder_detail h5 {
  width: 90%;
  word-break: break-all;
}

.payment__success p {
  color: #888;
  font-size: 18px;
}

.payment__success h2 {
  color: #4caf50;
  font-weight: 500;
  margin: 0 0 10px;
}

.payment__success svg {
  width: 200px;
  height: 200px;
  margin: -150px 0 20px;
  color: #4caf50;
  background: #fff;
  border-radius: 100px;
}

.payment__success {
  max-width: 600px;
  margin: 150px auto 0;
  text-align: center;
  background: #fff;
  border-radius: 15px;
  padding: 50px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  animation: draw 1s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  animation: draw 1s forwards 1s;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.subcription-popup-header {
  border-bottom: 0 !important;
}

.subcription-popup-footer {
  border-top: 0 !important;
}

table thead tr th,
table tbody tr td {
  border-block-width: 0 !important;
}

.username-icon-2 {
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}


.owner-card{
  /* max-width: 520px; */
  margin: 10px auto;
}
.owner-card .search-owner{
  border: 1px solid var(--primary-color);
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}
.owner-card .card-header{
  background-color:var(--primary-color);
  padding: 35px 15px;
  overflow: visible;
  border-radius: 10px 10px 0px 0px;
}
.owner-card .search-owner .card-body .avatar{
  width: 100px; 
  height: 100px;
  border-radius: 100px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: -60px auto 15px;
  padding: 5px;
}
.user-identifier{
  width: 60px;
  height: 60px;
  background: #e8f0fe;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: auto;
  color: #296cf5 !important;
}
.owner-card .search-owner .card-body .avatar img{
  width: 100% !important;
  height: 100%;
  border-radius: 100px !important;
}

.owner-card .search-owner .card-body{
  background-color: #e8f0fe;
  border-radius: 0px 0px 10px 10px;
}

.content-heading{
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.content{
  font-size: 16px;
  font-weight: 400;
  color: #535353;
}

.public-albums{
  background:#e8f0fe; 
  min-height: 100vh;
}

.public-album-data{
  border-radius: 10px;
  margin: 20px 0px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 0px 10px rgba(99, 99, 99, 0.2);
}

.profile-modal-info .modal-header{
  justify-content: center !important;
  position: relative;
  border-bottom: 0px;
}

.profile-modal-info .modal-header .btn-close{
  margin-left: 0px;
  position: absolute;
  right: 25px;
  z-index: 3;
}
.profile-modal-info .modal-header .modal-title{
  font-weight: 700;
}

.preview-media .modal-body .img-container{
  padding-top: 56.66%;
}
.add-folder-modal .modal-dialog{
  max-width: 500px;
}
.add-folder-modal .modal-content{
  width: 100%;
}

.subcription-popup-modal .subcription-popup-header,
.subcription-popup-modal .modal-body,
.subcription-popup-modal .modal-footer{
  background: #F3F8FF;

}
.subcription-popup-modal .modal-body  th{
  background-color: transparent;
}
.subcription-popup-modal .modal-body  tbody tr td{
  background-color: #ffffff;
  border-bottom: 5px solid #F3F8FF;
}
.subcription-popup-modal .modal-body  tbody tr td:first-child{
  border-radius: 5px 0px 0px 5px;
}
.subcription-popup-modal .modal-body  tbody tr td:last-child{
  border-radius: 0px 5px 5px 0px;
}
.subcription-popup-modal .modal-body table, .subcription-popup-modal .modal-body table td {
  border-spacing: 0px 10px !important;
  border-collapse: separate !important;
}
.create-folder-modal .form-container video{
  width: 100%;
  border-radius: 10px;
}
.modal.video-recoder-modal .form-container video{
  width: 100%;
  border-radius: 10px;
}

.invite-user-icon .username-icon{
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}.owner-list-container {
  padding-bottom: 60px; /* Space for the fixed pagination */
}

.fixed-pagination {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #dee2e6;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

.text-center {
  text-align: center;
  margin-bottom: 20px; /* Adjust spacing as needed */
}
.sidebar-list ul {
  padding: 0;
  margin: 0;
}

.sidebar-list ul li {
  list-style: none;
}

.sidebar-list ul li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.sidebar-list ul li a.active {
  background-color: #007bff;
  color: white;
}

.sidebar-list ul li a:hover,
.sidebar-list ul li a:focus,
.sidebar-list ul li a.active:hover,
.sidebar-list ul li a.active:focus {
  background-color: #f0f0f0;
  color: #333; /* Set the text color to default */
}
