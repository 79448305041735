.dashboard-wrapper {
  display: grid;
  grid-template-columns: 1fr 6fr;
}

.dashboard-wrapper > * {
  padding: 0 20px;
}

.sidebar-list {
  margin: 50px 0 0;
}

.sidebar-list button {
  border-radius: 10px !important;
  min-height: 60px;
  width: 100%;
  display: block;
  font-size: 18px;
  box-shadow: 2px 2px 5px 0 #00000040;
}

.sidebar-list button:hover {
  box-shadow: none !important;
}

.sidebar-list ul {
  list-style: none;
  padding: 10px 0 30px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sidebar-list ul li a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 25px;
  font-weight: 600;
  color: #000;
  border-radius: 50px;
}

.sidebar-list ul li.active a,
.sidebar-list ul li:hover a {
  background: #80aaff33;
}

.sidebar-list ul li a img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.storage-progress-bar {
  width: 100%;
  height: 5px;
  background: #c6c6c6;
  border-radius: 50px;
  margin: 0 0 5px !important;
  position: relative;
}

.storage-progress-bar .storage-progress-bar-indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  border-radius: 50px;
  height: 100%;
  background: var(--primary-color);
}

.storage-section > a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 0;
  font-weight: 600;
  color: #000;
  border-radius: 50px;
}

.storage-section > a img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.storage-section {
  padding: 10px;
}

.storage-section button {
  min-height: auto;
  padding: 5px 20px !important;
  border-radius: 5px !important;
  margin: 10px 0 0;
  font-size: 14px;
  width: auto;
  box-shadow: none;
}

.top-nav-section {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  font-size: 22px;
  align-items: center;
}

.top-nav-section .username-icon {
  width: 45px;
  height: 45px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.dashboard-inner-content-wrapper {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  height: calc(100vh - 130px);
  overflow-y: auto;
  box-shadow: 4px 4px 60px 0 rgb(0 0 0 / 10%);
}

.dashboard-inner-content-wrapper::-webkit-scrollbar {
  width: 5px;
}

.dashboard-inner-content-wrapper::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 50px;
}

.dashboard-inner-content-wrapper::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 50px;
}

.dashboard-heading {
  font-weight: 700;
}

.change_view {
  display: flex;
  align-items: center;
  padding: 0px 18px;
  border: 1px solid #aaa;
  border-radius: 50px;
  width: fit-content;
  gap: 20px;
  color: #aaa;
  font-size: 20px;
  position: relative;
  cursor: pointer;
}

.change_view:before {
  width: 1px;
  height: 100%;
  position: absolute;
  background: #aaa;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
}

.change_view i.active {
  color: var(--primary-color);
}

.filter-section {
  padding: 15px;
  background: #f2f7fe;
  display: flex;
  gap: 10px;
  position: relative;
}

.filter-section .edit-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
}

.upload-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 60vh;
  justify-content: center;
}

.icon-box-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  text-align: center;
}

.icon-box-container .icon-box-wrapper {
  max-width: 100px;
}

.icon-box-container .svg-icon-box {
  width: 70px;
  height: 70px;
  background: #296cf5;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.icon-box-container .svg-icon-box svg {
  width: 40px;
  height: 40px;
}

.icon-box-container .svg-icon-box svg path {
  fill: #fff;
}

.icon-box-container p {
  line-height: 1.4;
  color: #737373;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
}

.icon_box_section {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 20px;
}

.icon_box_section .icon-box {
  width: 50px;
  height: 50px;
  background: #5c5c5c;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_box_section .icon-box svg {
  width: 30px;
  height: 30px;
}

.icon_box_section .icon-box svg path {
  fill: #fff;
}

.icon_box_section p {
  line-height: 1.4;
  color: #737373;
  font-size: 14px;
  font-weight: 600;
}

.content_box_section {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  position: relative;
  margin: 10px 0;
}

.content_box_section img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.content_box_section .edit-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
}

.content_box_section .edit-icon .dropdown-toggle i {
  color: #fff !important;
}

.invite-user-list {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #296cf51a;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 #00000041;
  position: relative;
  margin: 0 0 15px;
}

.invite-user-list .invite-user-icon .username-icon {
  width: 70px;
  height: 70px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.invite-user-list .invite-user-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.invite-user-list .invite-user-info .invite-user-detail {
  flex: 1;
}

.invite-user-list .invite-user-info .invite-user-btn-group {
  display: flex;
  gap: 20px;
}

.invite-user-list .invite-user-info .invite-user-btn-group > * {
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  font-weight: 600;
}

.invite-user-list .invite-user-info .invite-user-btn-group > *:last-child {
  background: #d9d9d9 !important;
  color: #000 !important;
}

.invite-user-list .edit-icon {
  position: absolute;
  right: 25px;
  top: 5px;
  font-size: 22px;
}

.album_folder_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.album_folder_section {
  width: 100px;
  height: 80px;
  position: relative;
}

.album_folder_section .album_folder_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.album_folder_section .album_img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px 5px 0 0;
}

.album_folder_detail {
  flex: 1;
}

.album_folder_detail .album_folder_detail_views {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;
  color: #707070;
  font-size: 14px;
  font-weight: 600;
}

.album_folder_detail .album_folder_detail_views .avatars {
  display: flex;
  padding-left: 20px;
}

.album_folder_detail .album_folder_detail_views .avatars .avatar {
  margin-left: -14px;
}

.album_folder_detail .album_folder_detail_views .avatars .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.memory-card {
  width: 100%;
  border-radius: 20px;
  margin: 0 0 15px;
}

.memory-card-body {
  background: #296df51a;
  padding: 10px;
  border-radius: 10px;
}

.memory-card-body .edit_option-dropdown {
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin: 0 0 8px auto;
}

.memory-card-body .edit_option-dropdown button i {
  line-height: 1;
}

.memory-card-body .edit_option-dropdown .dropdown-menu {
  inset: 100% 0 0 auto !important;
  height: fit-content;
}

.memory-card-body img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.memory-card .memory-card-footer .avatars {
  padding-left: 25px;
}

.memory-card .memory-card-footer .avatars .avatar img {
  border-radius: 50px;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.memory-card .memory-card-footer {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0;
}

.memory-card .memory-card-footer .memory-card-footer-detail h6 {
  font-weight: 700;
  margin: 0 0 5px;
}

.memory-card .memory-card-footer .memory-card-footer-detail small {
  display: block;
  color: #707070;
  font-weight: 500;
}

.memory-tabs {
  border: none;
  gap: 10px;
}

.memory-tabs li button {
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  padding: 5px 20px;
  font-size: 14px;
}

.memory-tabs li button:hover {
  background: #f2f7fe !important;
}

.memory-tabs li button.nav-link.active {
  background: var(--primary-color) !important;
  color: #fff;
}
