/* desktop-css-start */

@media screen and (min-width: 1200px) {
  .memories-heading {
    font-size: 40px !important;
  }

  .login-header-btn {
    display: none;
  }

  .top-nav-menu {
    display: none;
  }

  .translateX {
    transform: unset;
  }

  .overlay {
    display: none;
  }

  .sidebar-overlay {
    display: none;
  }

  .sidebarlogo,
  .sidebar-addmemory {
    display: block;
  }

  .top-nav-section-mob-sidebar {
    display: none;
  }

  .bottom-bar {
    display: none;
  }

  .username-with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 90%;
  }

  .username-with-icon .username {
    font-family: 16px !important;
    font-weight: 800;
  }

  .billingPlans .bill__mobile--content {
    display: none;
  }
}

.modile-header-d-none {
  display: none;
}

.modile-header-d-block {
  display: block;
}

/* desktop-css-end */

/* iPad-css-start */

@media screen and (min-width: 768px) and (max-width: 1180px) {
  .memories-heading {
    font-size: 30px !important;
  }

  .navbar-brand img {
    max-width: 180px;
  }
  .grid-toggler .edit-icon{
    display: block !important;
  }
  .login-header-btn {
    display: none !important;
  }
  .login-header-btn.sign-in-page{
    display: none !important;
  }

  /* .top-nav-menu{
    display: none;
  } */
  .translateX {
    transform: unset;
  }

  .overlay {
    display: none;
  }

  .dashboard-wrapper {
    display: block !important;
  }

  .dashboard-inner-content-wrapper {
    padding: 20px 15px !important;
    background: #f3f8ff !important;
    border-radius: 0px !important;
    height: 100vh !important;
    box-shadow: none !important;
    margin-top: 60px !important;
    overflow-y: unset !important;
  }

  button.top-nav-menu {
    display: block !important;
    background-color: transparent !important;
    border: none;
    color: #5C5C5C;
    padding: 0px !important;
    font-size: 28px;

  }

  .mobile-header {
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 1;
    box-shadow: 10px 0px 0px #0000002d;
    padding: 10px !important;
  }

  .sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000073;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar.iss-open {
    transform: translateX(0%);
    height: 100vh;
  }

  .billing-container .user-info.w-50 {
    width: 75% !important;
  }

  .billing-container .invisible {
    visibility: visible !important;
  }

  .filter-section {
    align-items: flex-start !important;
  }

  /* .filter-section .flex-col{
  flex-direction: column !important;
} */
  .filter-section .d-flex:first-child {
    flex-wrap: wrap;
  }

  /* .top-nav-section{
  display: none !important;
} */
  .translateX {
    transform: translateX(-100%);
  }

  .overlay {
    display: none !important;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #F3F8FF;
    transform: translateX(-100%);
    transition: all 0.2s ease-in-out;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar.iss-open {
    transform: translateX(0%);
    height: 100vh;
    border-radius: 0px 15px 15px 0px;
  }

  .top-nav-section-mob-sidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 15px;
    border-bottom: 1px solid #C7D2DA;
  }

  .dashboard-sidebar {
    padding: 0px 0px !important;
  }

  .sidebar-list {
    padding: 0px 15px;
  }

  .sidebarlogo,
  .sidebar-addmemory {
    display: none;
  }

  .top-nav-section-mob-sidebar .username-icon {
    border-radius: 100px;
    width: 60px;
    height: 60px;
  }

  .navbar-brand {
    order: 2;
  }

  .top-mobile-menu {
    justify-content: space-between !important;
  }

  button.add-icon-btn {
    height: auto !important;
  }

  .dashbard-mb {
    display: none;
  }

  /* .account-user{
  flex-direction: column;
} */
  /* .account-user .outline-btn{
  align-self: flex-end;
} */
  .dashbard-mb {
    display: none !important;
  }

  .create-folder-modal .modal-dialog {
    width: 90%;
  }

  .account-container .d-flex:nth-child(6),
  .account-container .d-flex:nth-child(7) {
    flex-direction: column;
  }

  .account-container .d-flex:nth-child(6) .outline-btn,
  .account-container .d-flex:nth-child(7) .outline-btn {
    align-self: flex-end;
    margin-top: 10px;
  }

  .billingPlans .user-icon {
    visibility: visible !important;
  }

  .billingPlans .user-info {
    width: 75% !important;
  }

  .bottom-bar {
    display: block;
    width: 100%;
    padding: 3px 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px -2px 10px #00000026;
  }

  .bottom-bar ul li .navigate-popover-pages {
    font-size: 13px;
    line-height: 0.9;
  }

  .bottom-bar ul li .navigate-popover-pages i {
    font-size: 48px;
    color: #5C5C5C;

  }

  .bottom-bar .add-memory-btn {
    width: 45px;
    height: 45px;
    padding: 0px 0px !important;
    font-size: 28px;
  }

  .bottom-bar ul li .navigate-popover-pages i.active-tab {
    color: #076AFE;
  }

  .bottom-bar ul li span {
    font-size: 13px;
  }

  .table-container {
    white-space: nowrap;
  }

  .add-memory-tab-modal {
    z-index: 0;
  }

  .add-memory-tab-modal .modal-backdrop {
    display: none;
  }

  .add-memory-tab-modal .modal-dialog .modal-content {
    bottom: 90px;
    box-shadow: 0px -2px 10px #00000026;
  }

  .add-memory-tab-modal .modal-dialog {
    display: flex;
    align-items: flex-end;
    height: 100%;
    min-width: 100%;
    bottom: 25px;
  }

  .add-memory-tab-modal .modal-dialog .modal-content .modal-body {
    background-color: #DCE8FF;
  }

  .sidebar-addmemory {
    display: none !important;
  }

  .top-nav-section-mob-sidebar .ri-notification-3-line {
    font-size: 28px;
  }

  .sidebar-list {
    margin: 0px 0 0 !important;
  }

  .top-nav-section-mob-sidebar .username {
    width: 60px;
    height: 60px;
    background: #296cf5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    font-weight: 600;
  }

  .billingPlans .bill__mobile--content {
    display: none;
  }

  .top-nav-section {
    display: none !important;
  }

  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .offcanvas-body .navbar-nav a {
    display: block;
    width: 100%;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #C7D2DA;
    color: #000;
    font-weight: 600;
  }

  /* .top-nav-menu{
    display: block !important;
  } */

  .login-header-btn {
    display: block !important;
    order: 3;
    padding: 5px 10px !important;
  }

  /* .sidebar-overlay{
    z-index: 10;
  } */
}

/* iPad-css-end */

/* mobile-css-start */

@media (max-width: 767px) {
  .grid-info-executor {
    padding-bottom: 50px;
  }

  .navbar-brand img {
    max-width: 180px;
  }

  .memories-heading {
    font-size: 22px !important;
  }

  .login-btn {
    width: 50% !important;
  }

  .offcanvas-body {
    padding: 15px 0px;
  }

  .offcanvas-start {
    width: 80% !important;
  }

  .offcanvas-body .navbar-nav a {
    display: block;
    width: 100%;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #C7D2DA;
    color: #000;
    font-weight: 600;
  }

  .navbar-brand {
    order: 2;
  }

  .login-header-btn {
    display: block !important;
    order: 3;
    padding: 5px 10px !important;
  }

  .dashboard-layout .dashboard-wrapper {
    position: relative;
    padding-bottom: 90px;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #F3F8FF;
    transform: translateX(-100%);
    transition: all 0.2s ease-in-out;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar.iss-open {
    transform: translateX(0%);
  }

  .sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000073;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .translateX {
    transform: translateX(-100%);
  }

  .dashboard-wrapper {
    display: block !important;
  }

  button.top-nav-menu {
    display: block !important;
    background-color: transparent !important;
    border: none;
    color: #5C5C5C;
    padding: 0px !important;
    font-size: 28px;

  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000073;
    left: 0;
    top: 0;
    z-index: 2;
    display: none;
  }

  .top-nav-section {
    display: none !important;
  }

  .top-mobile-menu {
    justify-content: space-between !important;
  }

  .sidebar-open {
    transform: translateX(0);
  }

  .dashboard-main-content {
    padding: 0px 0px 90px !important;
  }

  .mobile-header {
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 1;
    box-shadow: 10px 0px 0px #0000002d;
    padding: 10px !important;
  }

  .dashboard-inner-content-wrapper {
    padding: 20px 15px !important;
    background: #f3f8ff !important;
    border-radius: 0px !important;
    height: 100vh !important;
    box-shadow: none !important;
    margin-top: 60px !important;
    /* height: calc(100vh - 130px); */
    overflow-y: unset !important;
  }

  .change_view .ri-list-check {
    display: none;
  }

  .dashboard-heading {
    font-size: 16px;
  }

  .dashboard-heading span {
    font-size: 16px !important;
  }

  .change_view:before {
    width: 0px !important;
  }

  .album_folder_detail h5 {
    width: 100% !important;
    font-size: 16px;
  }

  .grid-toggler {
    padding-bottom: 15px !important;
    border-bottom: 1px solid #C7D2DA;
    margin-bottom: 15px;
  }

  .filter-section {
    align-items: flex-start !important;
    flex-direction: column-reverse;
  }

  /* .filter-section .flex-col{
  flex-direction: column !important;
} */
  .filter-section .d-flex:first-child {
    flex-wrap: wrap;
  }

  .dashboard-layout .dashboard-wrapper .dashboard-sidebar.iss-open {
    transform: translateX(0%);
    min-height: 100%;
    border-radius: 0px 15px 15px 0px;
    position: fixed;
    z-index: 9999;
    width: 80%;
  }

  .sidebarlogo,
  .sidebar-addmemory {
    display: none !important;
  }

  .top-nav-section-mob-sidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 15px;
    border-bottom: 1px solid #C7D2DA;
  }

  .dashboard-sidebar {
    padding: 0px 0px !important;
  }

  .sidebar-list {
    padding: 0px 15px;
  }

  .top-nav-section-mob-sidebar .username-icon {
    border-radius: 100px;
    width: 60px;
    height: 60px;
  }

  button.add-icon-btn {
    height: auto !important;
    font-size: 13px;
  }

  .scroll-section {
    flex-wrap: nowrap;
    overflow-y: auto;
    width: 100%;
    justify-content: flex-start;
    padding: 10px;
    padding-bottom: 90px;
  }

  .scroll-section>.col-xl-3.col-lg-4.col-md-6 {
    max-width: 350px;
  }

  .sort_by-dropdown {
    width: 100% !important;
  }

  .filter-section .d-flex:nth-child(2) {
    width: 100%;
  }

  .modal {
    display: flex !important;
    justify-content: center;
    align-items: end;
  }

  .modal .modal-dialog {
    margin: 0px;
    width: 100%;
    align-items: end;
  }

  .modal .modal-content {
    margin: 0px;
    border-radius: 20px 20px 0px 0px;
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
  }

  .preview-media .modal-dialog,
  .add-folder-modal .modal-dialog {
    min-width: 100%;
  }

  .create-folder-modal .modal-content .select-folder-test .invite_access-dropdown {
    width: 100%;
    margin-top: 15px;
  }

  .create-folder-modal .modal-content {
    height: 600px;
    overflow-y: auto;
  }

  .plan-table .card {
    margin-bottom: 30px;
    height: auto !important;
  }

  .dashbard-mb {
    display: none !important;
  }

  .folder-list-item .folder-content .button-group {
    flex-wrap: wrap;
  }

  .account-user {
    flex-direction: column;
  }

  .account-user .outline-btn {
    align-self: flex-end;
    margin-top: 10px;
  }

  .account-container .outline-btn {
    font-size: 13px;
  }

  .account-user .user-icon {
    width: 66px !important;
    height: 60px !important;
  }

  .account-user .user-info .user-label,
  .user-info .user-label {
    font-size: 18px !important;
  }

  .account-user .user-info .user-txt,
  .user-info .user-txt {
    font-size: 13px !important;
  }

  .account-container .d-flex:nth-child(6),
  .account-container .d-flex:nth-child(7) {
    flex-direction: column;
  }

  .account-container .d-flex:nth-child(6) .outline-btn,
  .account-container .d-flex:nth-child(7) .outline-btn {
    align-self: flex-end;
    margin-top: 10px;
  }

  .billingPlans .user-icon {
    visibility: visible !important;
  }

  .billingPlans {
    flex-direction: column;
  }

  .billingPlans .user-info {
    width: 100% !important;
  }

  .bill__profile--container {
    gap: 10px;
  }

  .billingPlans .bill__mobile--content {
    display: block;
  }

  .billingPlans .bill__mobile--content .user-label {
    font-size: 18px !important;
  }

  .billingPlans .bill__mobile--content .user-txt {
    font-size: 13px !important;
  }

  .back-button {
    margin: 0px 0px 0px !important;
  }

  .bottom-bar {
    display: block;
    width: 100%;
    padding: 3px 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px -2px 10px #00000026;
  }

  .bottom-bar ul li .navigate-popover-pages {
    font-size: 13px;
    line-height: 0.9;
  }

  .bottom-bar ul li .navigate-popover-pages i {
    font-size: 48px;
    color: #5C5C5C;

  }

  .bottom-bar .add-memory-btn {
    width: 45px;
    height: 45px;
    padding: 0px 0px !important;
    font-size: 28px;
  }

  .bottom-bar ul li .navigate-popover-pages i.active-tab {
    color: #076AFE;
  }

  .bottom-bar ul li span {
    font-size: 13px;
  }

  .table-container {
    white-space: nowrap;
  }

  .add-memory-tab-modal {
    z-index: 0;
  }

  .add-memory-tab-modal .modal-backdrop {
    display: none;
  }

  .add-memory-tab-modal .modal-dialog .modal-content {
    bottom: 90px;
    box-shadow: 0px -2px 10px #00000026;
  }

  /* .dashboard-wrapper{
    p
  } */

  .schedule-date {
    justify-content: flex-start !important;
  }

  .privacy-heading {
    font-size: 22px !important;
  }

  .privacy-policy-para {
    font-size: 14px !important;
  }

  .privacy-policy-para-label {
    font-size: 16px !important;
  }

  .add-memory-tab-modal .modal-dialog .modal-content .modal-body {
    background-color: #DCE8FF;
  }

  .top-nav-section-mob-sidebar .ri-notification-3-line {
    font-size: 28px;
  }

  .sidebar-list {
    margin: 0px 0 0 !important;
  }

  .top-nav-section-mob-sidebar .username {
    width: 60px;
    height: 60px;
    background: #296cf5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    font-weight: 600;
  }

  .sidebar-overlay {
    z-index: 10;
  }

  .username-with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 90%;
  }

  .username-with-icon .username {
    font-family: 16px !important;
    font-weight: 800;
  }

  .account-holder-card .btn.w-50 {
    width: 100% !important;
  }

  .billing-container .user-info.w-50 {
    width: 100% !important;
  }

  .billing-container .invisible {
    visibility: visible !important;
  }

  .account-container {
    padding-bottom: 90px;
  }

  #popover-positioned-bottom {
    z-index: 9999;
  }

  .modal.video-recoder-modal {
    align-items: center !important;
  }

  .modal.video-recoder-modal .modal-content {
    height: 574px;
    overflow-y: auto;
    border-radius: 20px;
    width: 95%;
    margin: auto;
  }

  .modal.video-recoder-modal .modal-content .video-container {
    height: auto;
    position: relative;
    padding-top: 75%;
    border-radius: 20px;
  }

  .navbar-expand-lg .login-header-btn {
    width: 100% !important;
    margin-top: 15px !important;
  }

  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
  }

  .modal.video-recoder-modal .form-container video {
    width: 100%;
    border-radius: 10px;
  }

  .create-folder-modal .form-container video {
    width: 100%;
    border-radius: 10px;
  }
  .grid-info-executor{
    padding-bottom: 50px;
    }
    .modal.video-recoder-modal .modal-content .video-container {
      height: auto ;
      position: relative;
      padding-top: 75%;
      border-radius: 20px;
    }
    .navbar-expand-lg .login-header-btn{
      width: 100% !important;
      margin-top: 15px !important;
    }
    .navbar-brand{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 15px;
    }
    .modal.video-recoder-modal .form-container video{
      width: 100%;
      border-radius: 10px;
    }
    .create-folder-modal .form-container video{
      width: 100%;
      border-radius: 10px;
    }
    .invite-user-icon .username-icon{
      width: 50px !important;
      height: 50px !important;
      border-radius: 100px;
      background-color: var(--primary-color);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .grid-toggler .edit-icon{
      display: block !important;
    }
    .tab-content{
      padding-bottom: 90px;
    }
    .login-header-btn.sign-in-page{
      display: none !important;
    }
}



.bottom-bar ul {
  list-style: none;
}

/* mobile-css-end */

.loader-container {
  /* content: ""; */
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: #00000077;
  z-index: 2;
  position: fixed !important;
}